<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card
        v-if="warrant"
        id="content"
        ref="content"
      >
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="appLogo"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3 "
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ appName }}
                </span>
              </div>
              <span class="d-block">Bulevar Kralja Aleksandra 124 (pasaž)</span>
              <span class="d-block">11000 Beograd, Srbija</span>
              <span class="d-block">011/2457-666, office@sistemcd.rs</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4">
                {{ $t('invoice') }} #{{ platform_reference_number }}
              </p>
              <p class="mb-2">
                <span>{{ $t('Date') }}: </span>
                <span class="font-weight-semibold">{{ date }}</span>
              </p>
              <p class="mb-2">
                <span>{{ $t('Deadline') }}: </span>
                <span class="font-weight-semibold">{{ deadline }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div class="mb-8 mb-sm-0">
              <p class="font-weight-semibold payment-details-header">
                {{ $t('Invoice for') }}:
              </p>
              <p class="mb-1">
                {{ referent_name }}
              </p>
              <p class="mb-1">
                {{ company.name }}
              </p>
              <p class="mb-1">
                {{ company.address }}, {{ company.country }}
              </p>
              <p class="mb-1">
                {{ company.phone }}
              </p>
              <p class="mb-0">
                {{ company.email }}
              </p>
              <p class="mb-0">
                PIB: {{ company_identification_number }}
              </p>
            </div>
            <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
              <div class="mb-8 mb-sm-0">
                <p class="font-weight-semibold payment-details-header">
                  {{ $t('Billing to') }}:
                </p>
                <p class="mb-1">
                  {{ referent_name }}
                </p>
                <p class="mb-1">
                  {{ company.name }}
                </p>
                <p class="mb-1">
                  {{ company.address }}, {{ company.country }}
                </p>
                <p class="mb-1">
                  {{ company.phone }}
                </p>
                <p class="mb-0">
                  {{ company.email }}
                </p>
                <p class="mb-0">
                  PIB: {{ company_identification_number }}
                </p>
              </div>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ $t('Code') }}
                </th>
                <th>
                  {{ $t('Name') }}
                </th>
                <th>
                  {{ $t('Price') }}
                </th>
                <th>
                  {{ $t('Quantity') }}
                </th>
                <th class="text-right">
                  {{ $t('Subtotal') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in items"
                :key="`${item.code}-${i}`"
              >
                <td class="text-no-wrap">
                  {{ item.code }}
                </td>
                <td class="text-no-wrap">
                  {{ item.name }}
                </td>
                <td>
                  {{ ((item.price * 1.2).toFixed(2)).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td class="text-right">
                  {{ ((item.price * 1.2).toFixed(2) * item.quantity).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <div class="mb-2 mb-sm-0">
              <p class="mb-1">
                <span class="font-weight-semibold">{{ $t('Created by') }}:</span>
                <span>
                  {{ location.created_by ? location.created_by.first_name : "" }}
                  {{ location.created_by ? location.created_by.last_name : "" }}
                </span>
              </p>
              <p>{{ $t('Thank you for your cooperation') }}</p>
              <img
                id="barcode"
                alt="barcode"
              />
            </div>
            <div>
              <table>
                <tr>
                  <td class="pe-16">
                    {{ $t('Total without discount and taxes') }}:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ (getTotal()).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    {{ $t('Discount') }}:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ job.discount }}%
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    {{ $t('Tax') }}:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    20%
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    {{ $t('Total') }}:
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ (getTotalWithDiscount()).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </th>
                </tr>
              </table>
              <v-divider class="mt-12 mb-3"></v-divider>
              <div class="text-center mt-0 pt-0">
                (Primio)
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <p class="mb-0">
            <span class="font-weight-semibold">{{ $t('Message') }}: </span>
            <span>{{ $t('It was a pleasure to work with you!') }}</span>
          </p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            @click="printInvoice"
          >
            {{ $t('Print') }}
          </v-btn>

          <v-btn
            class="mb-3"
            block
            small
            outlined
            @click="back()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import axiosIns from '@axios'
import JsBarcode from 'jsbarcode'

export default {
  data() {
    const warrant = ref(null)

    const printInvoice = () => {
      window.print()
    }

    return {
      warrantId: false,
      dateMenu: false,
      company: '',
      referent_name: null,
      address: null,
      phone: null,
      email: null,
      company_identification_number: null,
      companies: [],
      job: {},
      location: {},
      snackbar: false,
      by_post: false,
      invoice: false,
      add_to_account: false,
      clients_material: false,
      is_paid: false,
      informed: false,
      message_sent: false,
      is_delivered: false,
      note: null,
      items: [],
      deleted_items: [],
      platform_reference_number: '',
      warrant_code: '',
      warrant,
      printInvoice,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
  computed: {
    date() {
      const date = new Date()

      return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
    },

    deadline() {
      const date = new Date()
      date.setMonth(date.getMonth() + 1)

      return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
    },
  },
  mounted() {
    axiosIns({
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      url: `/admin/warrants/${this.$route.params.id}`,
    })
      .then(res => {
        const { warrant } = res.data
        this.company = warrant.company
        this.referent_name = warrant.referent_name
        this.phone = warrant.phone
        this.address = warrant.address
        this.email = warrant.email
        this.company_identification_number = warrant.company_identification_number
        this.job.file_path = warrant.file_path
        this.job.payment_method = warrant.payment_method
        this.job.deadline = warrant.deadline
        this.job.discount = warrant.discount ? Number.parseInt(warrant.discount, 10) : 0
        this.by_post = Number.parseInt(warrant.by_post, 10)
        this.invoice = warrant.invoice
        this.add_to_account = Number.parseInt(warrant.add_to_account, 10)
        this.clients_material = Number.parseInt(warrant.clients_material, 10)
        this.is_paid = warrant.is_paid
        this.location.received_at_location = warrant.received_at_location
        this.location.takeover_location = warrant.takeover_location
        this.location.shelf = warrant.shelf
        this.note = warrant.note
        this.items = warrant.warrant_containers.length ? warrant.warrant_containers : []
        this.platform_reference_number = warrant.platform_reference_number
        this.warrant_code = warrant.warrant_code
        this.location.created_by = warrant.user
        this.informed = warrant.informed
        this.message_sent = warrant.message_sent
        this.is_delivered = warrant.is_delivered
        JsBarcode('#barcode', `${this.platform_reference_number}`, {
          width: 2,
          height: 40,
          displayValue: false,
          margin: 0,
        })
      })
      .catch(() => {})
  },
  methods: {
    back() {
      window.close()
    },
    getTotal() {
      return this.items.length > 0
        ? this.items.reduce((acc, curr) => acc + +((curr.price * 1.2).toFixed(2)) * curr.quantity, 0) / 1.2
        : 0
    },
    getTotalWithDiscount() {
      const totalWithDiscount = this.job.discount && this.getTotal()
        ? this.getTotal() - this.getTotal() * (this.job.discount / 100)
        : this.getTotal()

      // add VAT
      return totalWithDiscount * 1.2
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

table * {
  height: 2em!important;
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
